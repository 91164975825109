


















































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Logo55',
})
export default class Logo55 extends Vue {}
